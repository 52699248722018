
class SignalSocket
{
    constructor(url, role)
    {
        this.onmessage = undefined;
        this.socket = undefined;
        this.role = role;

        this.openListener = []
        this.messageListener = []

        this.url = url;

        this.connect();


        let obj = this;
        // send ping
        setInterval(()=>{
            obj.sendPing();
        }, 20*1000);

        // connect signal server forever
        setInterval(()=>{
            if (obj.socket && obj.socket.readyState == WebSocket.CLOSED) {
                console.log(`connect to ${this.role} server...`)
                obj.connect();
            }
        }, 5000);
    }

    sendPing() {
        if (this.socket && this.socket.readyState == WebSocket.OPEN) {
            this.sendMessage("ping");
        }
    }

    connect() {
        let obj = this;
        this.socket = new WebSocket(this.url);

        // Listen for messages
        this.socket.addEventListener("message", (event)=> {
            console.log(`message from ${this.role} server , ${event.data}`);

            obj.messageListener.forEach((listener, idx)=>{
                const msg = JSON.parse(event.data);
                listener(msg);
            });
        });

        // Connection opened
        this.socket.addEventListener("open", (event)=> {
            console.log(`${this.role} server connected.`);

            obj.openListener.forEach((listener, idx)=>{
                listener();
            });
        });

        // this.socket.addEventListener("error", function (event) {
        //     console.log("WebSocket error1: ", event);
        // });
        //
        // this.socket.onerror = function(error) {
        //     console.error('WebSocket error2:', error);
        // };

        this.socket.onclose = function(event) {
            console.log(`${obj.role} server connection is closed. code: ${event.code}`);
        };
    }

    addOpenListener(listener) {
        this.openListener.push(listener)
    }

    addMessageListener(listener) {
        this.messageListener.push(listener)
    }

    sendMessage(msg) {
        this.socket.send(msg);
        console.log(`${this.role} server send ${msg}`);
    }

    close() {
        if (this.socket) {
            this.socket.close();
        }
    }
}

export default SignalSocket;
