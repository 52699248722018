import React, { useEffect } from 'react';

import "./CloudDiskPage.css"
import Util from "../../LivePage/Util";

const CloudDiskPage = (props) => {

    useEffect(()=>{
        return ()=>{

        }
    }, []);

    const url = `https://saas.xiaoyunduoyuan.com/mydesktop?type=2&token=${window.user_token}&userid=${Util.getUrlParam('uid')}`;
    console.log("--->>>>> ", url)

    return (
        <div className="cloud_disk_page_container">
            <iframe className="cloud_disk_page_container_iframe" allow="same-origin allow-credentials" src={url}></iframe>
        </div>
    )
}

export default CloudDiskPage;
