import React from 'react';
import ReactDOM from 'react-dom/client';
import './Styles/reset.css'
import './index.css';
import zh_CN from 'antd/es/locale/zh_CN';
import AppRouter from './Router/AppRouter.js'
import { HashRouter } from 'react-router-dom'
import reportWebVitals from './reportWebVitals';
import {UserInfo, RoomInfo} from "./LivePage/Context";
import Util from "./LivePage/Util";
import ConsolePage from "./Console/ConsolePage";
import {ConfigProvider} from "antd";
import LivePage_Student from "./LivePage/student/LivePage_Student";
import LivePage_Teacher from "./LivePage/teacher/LivePage_Teacher";
import {DataProvider_Student} from "./LivePage/student/DataProvider_Student";

// set id and name
UserInfo.ID = Util.getUrlParam('id');
UserInfo.NAME = Util.getUrlParam('name');
UserInfo.ROLE = Util.getUrlParam('role');

// load room info
RoomInfo.ID = Util.getUrlParam('room_id');
RoomInfo.Aid = Util.getUrlParam('aid');
RoomInfo.Vid = Util.getUrlParam('vid');

console.log(RoomInfo, UserInfo)

window.setCookie = function setCookie(name, value, days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";

    console.log(document.cookie)
}

// set token
if (true) {
    let token = Util.getUrlParam('token');
    window.setCookie("usertoken", token, 1000);
    window.user_token = token;
}

window.CallBridge.addEventListener( "set_user_info", (info)=> {
    window.user_info = JSON.parse(info);
    console.log("set user info: ", window.user_info);
});

window.CallBridge.addEventListener( "set_course_info", (info)=> {
    window.course_info = JSON.parse(info);
    console.log("set course info: ", window.course_info);
});

const root = ReactDOM.createRoot(document.getElementById('root'));

if (Util.getUrlParam('pagerole') == "console") {
    // 设置标题
    document.title = "控制台"
    root.render(
        <ConfigProvider locale={zh_CN}>
            <HashRouter>
                <ConsolePage/>
            </HashRouter>
        </ConfigProvider>
    );
} else if (Util.getUrlParam('pagerole') == "stu") {
    // 设置标题
    document.title = "直播间"
    // 定义一个函数来检查条件并执行操作
    function checkCondition() {
        console.log("Checking condition...");

        // 如果条件满足，清除定时器
        if (window.course_info) {

            clearInterval(timer);
            console.log("Condition met, timer stopped.");

            root.render(
                <ConfigProvider locale={zh_CN}>
                    <HashRouter>
                        <DataProvider_Student>
                        <LivePage_Student/>
                        </DataProvider_Student>
                    </HashRouter>
                </ConfigProvider>
            );
        }
    }

    // 设置一个定时器，每隔一段时间执行一次 checkCondition 函数
    let timer = setInterval(checkCondition, 100);

} else if (Util.getUrlParam('pagerole') == "tea") {

    // 设置标题
    document.title = "教师直播间"
    // 定义一个函数来检查条件并执行操作
    function checkCondition() {
        console.log("Checking condition...");

        // 如果条件满足，清除定时器
        if (window.course_info) {

            clearInterval(timer);
            console.log("Condition met, timer stopped.");

            root.render(
                <ConfigProvider locale={zh_CN}>
                    <HashRouter>
                        <LivePage_Teacher/>
                    </HashRouter>
                </ConfigProvider>
            );
        }
    }

    // 设置一个定时器，每隔一段时间执行一次 checkCondition 函数
    let timer = setInterval(checkCondition, 100);

} else {
    root.render(
        <ConfigProvider locale={zh_CN}>
            <HashRouter>
                <AppRouter/>
            </HashRouter>
        </ConfigProvider>
    );
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
