import React, { useRef, useEffect, useState, createRef } from 'react';
import Util from "./Util";
import SignalSocket from "./SignalSocket";
import MsgSerial, {MsgType} from "./MsgSerial";
import {NetSocket, UserInfo, UserStatus} from "./Context";
import {AudioOutlined, VideoCameraOutlined} from '@ant-design/icons';
import { Button} from 'antd';
import './Link.css'

const serial = new MsgSerial();

const Link = () => {

    let [linkChanged, setLinkChanged] = useState(1);
    useEffect(()=>{

        // // build signal socket
        // const signalSo = new SignalSocket(Util.getSignalUrl(), "signal");
        // signalSo.addOpenListener(()=>{
        //     signalSo.sendMessage(serial.serial(MsgType.Type_Info, UserInfo.ID, UserInfo.NAME, 'join room'));
        // });
        //
        // NetSocket.SignalSo = signalSo;
        //
        // signalSo.addMessageListener((msg)=>{
        //     if (msg.id == UserInfo.ID) return;
        // });

        console.log("start push url= ", Util.getPushUrl())
        window.pushRTC(Util.getPushUrl(), "local_video");

        let timerId = setTimeout(()=>{
            //signalSo.sendMessage(serial.serial(MsgType.Type_Ping, UserInfo.ID, UserInfo.NAME, JSON.stringify(UserStatus)));
        }, 1000*10)

        return ()=>{
            //signalSo.close();
        }
    }, []);

    function onMuteVideo() {
        window.muteVideo();
    }

    function onMuteAudio() {
        window.muteAudio();
    }

    return (
        <div className="head-s">
            <div id="local_video">
                <div className="push_mask">
                    <div className="push_mask_bar">
                        <Button icon={<VideoCameraOutlined style={{ color: 'rgb(200, 200, 200)', fontSize: '20px' }}/>} type="text" onClick={onMuteVideo}/>
                        <Button icon={<AudioOutlined style={{ color: 'rgb(200, 200, 200)', fontSize: '20px' }}/>} type="text" onClick={onMuteAudio}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Link;
