import axios from 'axios'
import Util from "../../LivePage/Util";

// axios的配置文件, 可以在这里去区分开发环境和生产环境等全局一些配置
const devBaseUrl = 'https://space-api.xiaoyunduoyuan.com'
const proBaseUrl = 'https://space-api.xiaoyunduoyuan.com'

// process.env返回的是一个包含用户的环境信息,它可以去区分是开发环境还是生产环境
export const BASE_URL =  process.env.NODE_ENV === 'development' ? devBaseUrl : proBaseUrl
export const TIMEOUT = 5000

let request = axios.create({
    baseURL:BASE_URL,//基准地址
    timeout:TIMEOUT,
    headers: {
        "Authorization" : Util.getUrlParam('token'),
        "x-cloud-teamuuid": "personal"
    }
})

//拦截请求
request.interceptors.request.use((config)=>{
    return config
})

//拦截响应
request.interceptors.response.use((response)=>{
        return response
    },function (error){
        //对响应的错误做点什么
        return Promise.reject(error);
    }
)

export default request;
