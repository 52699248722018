import React from 'react';

const TextWithSpace = ({lspace, rspace, children}) => {

    const ss = {whiteSpace: 'pre'}

    function getLeftSpace() {

        let ret = "";
        if (lspace) {
            for (let i = 0; i < lspace; ++i) {
                ret += ' '
            }
        }

        return ret;
    }

    function getRightSpace() {
        let ret = "";
        if (rspace) {
            for (let i = 0; i < rspace; ++i) {
                ret += ' '
            }
        }

        return ret;
    }

    return (
        <span style={ss}>
            {getLeftSpace()}{children}{getRightSpace()}
        </span>
    )
}

export default TextWithSpace;
