import React, {useEffect, useState} from 'react';
import './RtcPlayPage_Teacher.css'
import Util from "../Util";

function RtcPlayItem({rid}) {

    useEffect(()=>{

        // function loadVideo() {
        //     let ele = document.getElementById(rid);
        //     if (ele) {
        //         console.log("play uuid, ", document.getElementById(rid))
        //         window.loadVideo("webrtc://play.xiaoyunduoyuan.com/live/123", rid);
        //     } else {
        //         setTimeout(loadVideo, 100)
        //     }
        // }
        // setTimeout(loadVideo, 100)

        console.log("play uuid, ", document.getElementById(rid))
        window.loadVideo("webrtc://play.xiaoyunduoyuan.com/live/s_9", rid);

    }, [])


    return (
        <div className="video-container">
            <video className="video_rtc" id={rid} preload="auto" playsInline webkit-playsinline="true"/>
        </div>
    );
}

let messageCache = [];
messageCache.push({
    uuid: "s" + Util.guid().substr(0, 8),
})

function RtcPlayPage_Teacher({ message }) {
    let [updateMsg, setUpdateMsg] = useState(1);

    useEffect(()=>{

    }, [])

    return (
        <div className="top_rtc">
            {
                messageCache.map((msg, index) => (
                    <RtcPlayItem key={msg.uuid} rid={msg.uuid}/>
                ))
            }

        </div>
    );
}

export default RtcPlayPage_Teacher;
