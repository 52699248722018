import React, {useContext, useEffect, useState} from 'react';
import {Select, Modal, Input, DatePicker} from "antd";
import {DataContext} from "../DataProvider";
import moment from 'moment';
import TimePicker from "../../Components/Common/TimerPicker";
import 'rc-time-picker/assets/index.css';
import "./CreatePublicCourseDialog.css"
import "../../Components/Common/Common.css"

const CreatePublicCourseDialog = (props) => {

    const {createPublicCourseDialog, setCreatePublicCourseDialog} = useContext(DataContext);

    // 课程时长
    const [courseTime, setCourseTime] = useState([0, 45]);

    const handleOk = () => {
        setCreatePublicCourseDialog(false);
    };
    const handleCancel = () => {
        setCreatePublicCourseDialog(false);
    };

    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };

    const dropdownStyle = {
        textAlign: "left"
    }

    return (
        <Modal
            open={createPublicCourseDialog}
            title="创建公开课"
            onOk={handleOk}
            onCancel={handleCancel}
            transitionName=""  // 禁用 Modal 弹出动画
            maskTransitionName=""  // 禁用遮罩层动画
            mask={false}
            footer={(_, {OkBtn, CancelBtn}) => (
                <>
                    <CancelBtn/>
                    <OkBtn/>
                </>
            )}
        >
            <div className="dialog_line"></div>
            <div className="class_normal">机构名称</div>
            <Select
                defaultValue=""
                className="custom-select"
                style={{
                    width: "100%",
                }}
                dropdownStyle={dropdownStyle}
                onChange={handleChange}
                options={[
                    {
                        value: '我的机构',
                        label: '我的机构',
                    },
                ]}
            />
            <div className="class_normal">公开课名称</div>
            <Input></Input>
            <div className="class_normal">开始时间</div>
            <DatePicker
                style={{
                    width: "100%",
                }}

                showTime={{
                    format: 'HH:mm',
                }}

                onChange={(value, dateString) => {
                    console.log('Selected Time: ', value);
                    console.log('Formatted Selected Time: ', dateString);
                }}
                //onOk={onOk}
            />
            <div className="class_normal">课程时长</div>
            <TimePicker

                style={{
                    width: "200px",
                }}

                onChange={(h, m)=>{
                    setCourseTime([h, m]);
                }}

                defaultTime = {courseTime}

                placeholder="选择时间"
            />

        </Modal>
    );
}

export default CreatePublicCourseDialog;
