import React, {useMemo, useState} from "react";

function ImageButton(props) {

    const ImageStyle = {
        width: "80px",
        height: "80px"
    }

    const TextStyle = {
        textAlign: "center"
    }

    return (
        <div className="centered-component" style={props.style} onClick={props.onClick}>
            <img src={props.src} className="centered-image" style={ImageStyle}/>
            <p className="centered-text" style={TextStyle}>{props.children}</p>
        </div>
    );
}
export default ImageButton;
