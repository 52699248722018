import React, {useMemo, useState} from "react";

function LineWidget(props) {
    const {width, height, color} = props;

    let ls = {
        width: width,
        height: height,
        backgroundColor: "transparent"
    }


    if (width) {
        ls.width = width;
    }

    if (height) {
        ls.height = height;
    }

    if (color) {
        ls.backgroundColor = color;
    }

    return (
        <div style={ls}>
            &nbsp;
        </div>
    );
}
export default LineWidget;
