import React, { useEffect, useState, createRef } from 'react';
import { Button, Input} from 'antd';
import './BarItem.css'
import Checkbox from "antd/es/checkbox";

const BarItem = (props) => {

    let thisRef = createRef();

    useEffect(()=>{
        return ()=>{

        }
    }, []);

    // 移除所有 div 的 selected 类
    function removeChildClass(div) {
        let line = div.getElementsByClassName("bar_item_left_line")[0];
        let pic = div.getElementsByClassName("bar_item_pic")[0];
        let text = div.getElementsByClassName("bar_item_name")[0];

        line.classList.remove("bar_item_line_selected");
        let src = pic.src;
        pic.src = pic.src.replace("_select", "_normal");
        text.classList.remove("bar_item_name_selected");
    }

    function addChildClass(div) {
        let line = div.getElementsByClassName("bar_item_left_line")[0];
        let pic = div.getElementsByClassName("bar_item_pic")[0];
        let text = div.getElementsByClassName("bar_item_name")[0];

        line.classList.add("bar_item_line_selected");
        let src = pic.src;
        pic.src = pic.src.replace("_normal", "_select");
        text.classList.add("bar_item_name_selected");
    }

    function onItemClick(e) {
        props.onClick(props.id)
        const divs = document.querySelectorAll('.bar_item_container');
        divs.forEach((div) => removeChildClass(div));
        addChildClass(thisRef.current);
    }



    return (
        <div className="bar_item_container" onClick={onItemClick} ref={thisRef}>
            <div className="bar_item_left_line"/>
            <div className="bar_item_flex">
                <img className={"bar_item_pic"} style={{width: "32px", height: "32px"}} src={props.default?  props.itemImgSrc.replace("_normal", "_select"):  props.itemImgSrc}/>
                <div className={props.default?"bar_item_name bar_item_name_selected":"bar_item_name"}>{props.itemText}</div>
            </div>
        </div>
    )
}

export default BarItem;
