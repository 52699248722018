import React, {useEffect, useRef} from 'react';
import moment from 'moment';
import "./CourseListItem.css"
import OBSWebSocket, {EventSubscription} from 'obs-websocket-js';

const CourseListItem = (props) => {

    const itemRef = useRef();

    useEffect(()=>{

        return ()=>{

        }
    }, []);

    function getTimeDifference(startTime, endTime) {
        // 将时间字符串转换为 moment 对象
        const start = moment(startTime);
        const end = moment(endTime);

        // 计算时间差
        const duration = moment.duration(end.diff(start));

        // 获取小时和分钟
        const hours = Math.floor(duration.asHours());
        const minutes = duration.minutes();

        if (hours == 0) {
            return `${minutes}分钟`;
        }

        return `${hours}小时${minutes}分钟`;
    }

    function formatDate(date) {
        const daysOfWeek = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
        const dayOfWeek = daysOfWeek[date.day()];

        return date.format(`YYYY/MM/DD HH:mm ${dayOfWeek}`);
    }

    function checkTimeStatus(timeA, timeB) {
        const now = new Date(props.data.current_time);
        const startTime = new Date(timeA);
        const endTime = new Date(timeB);

        // 计算时间差（以毫秒为单位）
        const diff = now - startTime;
        const diffInMinutes = diff / (1000 * 60); // 将时间差转换为分钟

        const diff2= now - endTime;
        const diffInMinutes2 = diff2 / (1000 * 60); // 将时间差转换为分钟

        console.log(diffInMinutes, diffInMinutes2)

        if ((startTime - now) > 30*60*1000 ) {
            return "备课";
        }

        if ((startTime - now) <= 30*60*1000 || (now - endTime) <= 30*60*1000) {
            return "上课";
        }

        if ((now - endTime) > 30*60*1000) {
            return "课程结束";
        }
    }

    async  function enterClass() {
        window.CallBridge.invokeMethod("enter_class", JSON.stringify(props.data));

        // const obs = new OBSWebSocket();
        // const {
        //     obsWebSocketVersion,
        //     negotiatedRpcVersion
        // } = await obs.connect();
        //
        // console.log(`Connected to server ${obsWebSocketVersion} (using RPC ${negotiatedRpcVersion})`)
        // obs.send('Quit');
    }

    return (
        <div className="class_item_main_container">
            <div className="course-order-container">{props.order}</div>
            <div className="course-vlay-container">
                <div className="course-item-name">{props.data.name}</div>
                <div>人数：10人</div>
                <div>开始时间：{formatDate(moment(props.data.begin_time))} &nbsp;&nbsp; ({getTimeDifference(props.data.begin_time, props.data.end_time)})</div>
            </div>
            <div style={{flexGrow: 1}}></div>
            <div className="class-state" onClick={enterClass}>
                <div>{checkTimeStatus(props.data.begin_time, props.data.end_time)}</div>
            </div>
        </div>
    )
}

export default CourseListItem;
