import React from 'react';

const Space = ({width, height}) => {

    const ss = {}

    if (width) {
        ss.width = width;
    }

    if (height) {
        ss.height = height;
        ss.display = "block";
    }

    return (
        <span style={ss}>

        </span>
    )
}

export default Space;
