const MsgType = {
    Type_Info: "info",
    Type_ChatCount: "chat_count",
    Type_Chat: "chat",
    Type_Ping: "ping",
    Type_Pong: "pong",
    Type_Rtc: "rtc"
}

class MsgSerial {
    constructor() {

    }

    serial(type, senderId, senderName, content) {
        const msg = {
            t: type,
            si: senderId,
            sn: senderName,
            c: content
        }

        return JSON.stringify(msg);
    }
}

export default MsgSerial;
export {MsgType};

