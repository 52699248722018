import React, {useEffect, useRef} from 'react';
import { MessageOutlined } from '@ant-design/icons';
import "./ClassListItem.css"

const ClassListItem = ({data, onClick}) => {

    const itemRef = useRef();

    useEffect(()=>{
        return ()=>{

        }
    }, []);

    function onItemClicked() {
        const boxes = document.querySelectorAll('.class_main_container');
        boxes.forEach((b) => {
            b.classList.remove('active')
            console.log(b)
        });

        onClick(data)

        itemRef.current.classList.add('active');
    }

    return (
        <div className="class_main_container" onClick={onItemClicked} ref={itemRef}>
            <div className="class_container">
                <img src="imgs/default_class.jpg"  className="class_head_pic"/>
                <span>{data.name}</span>
                <span style={{flexGrow: 1}}></span>
            </div>
        </div>
    )
}

export default ClassListItem;
