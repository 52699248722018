import React, {useContext, useEffect, useState} from 'react';
import {Button, Input, Modal, Upload} from "antd";
import {DataContext} from "../DataProvider";
import request from "../../Components/net/request";

const CreateClass = ({onOk}) => {

    const {createClassDialog, setCreateClassDialog} = useContext(DataContext);
    const [classNameText, setClassNameText] = useState("");
    const [isDisabled, setIsDisabled] = useState(false);

    const [fileList, setFileList] = useState([
        {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        },
    ]);

    const handleOk = () => {

        setIsDisabled(true);

        request.post("/api/v1/course/regular/create", {
            "name": classNameText,
            "cover_image": "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
        }).then(response => {
            console.log('Response data:', response.data);
            setCreateClassDialog(false);
        }).catch((err)=>{
            setIsDisabled(false);
        });
    };
    const handleCancel = () => {
        setCreateClassDialog(false);
    };

    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };

    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    function onTextChange(e) {
        setClassNameText(e.target.value);
    }

    return (
        <div>
            <Modal
                open={createClassDialog}
                title="创建班级"
                onOk={handleOk}
                onCancel={handleCancel}
                transitionName=""  // 禁用 Modal 弹出动画
                maskTransitionName=""  // 禁用遮罩层动画
                mask={false}
                footer={
                    [
                        <Button key="back" onClick={handleCancel}>
                            取消
                        </Button>,
                        <Button key="submit" type="primary" onClick={handleOk} disabled={isDisabled}>
                            提交
                        </Button>,
                    ]
                }
            >
                <div className="dialog_line"></div>
                <div className="class_normal">班级名称</div>
                <Input placeholder="请输入不超过40个字的班级名称" onChange={onTextChange}
                />
                <div className="class_normal">选择班级头像</div>
                <Upload
                    action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                    listType="picture-card"
                    fileList={fileList}
                    onChange={onChange}
                    onPreview={onPreview}
                >
                    {fileList.length < 2 && '+ Upload'}
                </Upload>
            </Modal>
        </div>
    );
}

export default CreateClass;
