import React, { createContext, useState } from 'react';

const DataContext_Student = createContext();

function DataProvider_Student({ children }) {
    const [rtcList, setRtcList] = useState([]);

    // rtc连麦列表信息更新通知，可以订阅这个消息
    const [rtcListChanged, setRtcListChanged] = useState(1);

    return (
        <DataContext_Student.Provider value={{
            rtcList, setRtcList,
            rtcListChanged, setRtcListChanged
        }}>
            {children}
        </DataContext_Student.Provider>
    );
}

export { DataContext_Student, DataProvider_Student };
