import React, {useEffect, useRef, useState} from 'react';
import LineWidget from "../../Components/Common/LineWidget";
import { Input, Button } from 'antd';
import "./JoinClass.css"
import "../../Components/Common/Common.css";
import Space from "../../Components/Common/Space";
import TextWithSpace from "../../Components/Common/TextWithSpace";
import request from "../../Components/net/request";

const JoinClass = () => {

    const [classId, setClassId] = useState(0);
    const [classData, setClassData] = useState({});
    // 视图切换
    const [activeView, setActiveView] = useState('view-empty');

    useEffect(()=>{
        return ()=>{

        }
    }, []);

    const renderView = () => {
        switch (activeView) {
            case 'view-empty':
                return (
                    <div className="empty-container">
                        没有找到搜索条件的班级
                    </div>
                )
            case 'view-data':
                return (
                    <div className="class-info">
                        <div className="class-info-title">
                            <img className="margin-left-10" src={classData.cover_image ? classData.cover_image : ""} style={{width: '48px', height: '48px'}}/>
                            <span className="margin-left-10">{classData.name ? classData.name : ""}</span>
                            <div style={{flexGrow: "1"}}></div>
                            <Button className="margin-right-10" onClick={onJoinClass}>加入班级</Button>
                        </div>
                        <Space height="15px"></Space>
                        <h5 className="margin-left-10">班级介绍</h5>
                        <Space height="10px"></Space>
                        <div className="margin-left-10">班主任什么也没留下</div>
                        <Space height="10px"></Space>
                        <h5 className="margin-left-10">班级成员（2）</h5>
                        <div>

                        </div>
                    </div>
                )
            default:
                return '';
        }
    };

    function onJoinClass() {
        request.post("/api/v1/course/member/join", {
            "course_uuid": classData.uuid,
        }).then(response => {
            console.log('Response data:', response.data);
        })
    }

    function onSearch() {
        if (classId.toString().length == 0) {
            setActiveView("view-empty");
            return;
        }

        // 开始获取班级列表数据
        const uri = `/api/v1/course/search?unique_code=${classId}`;
        request.get(uri).then(response => {
            const res = response.data;
            console.log(res)
            if (res.code == 200 && res.data && res.data.items.length > 0) {
                //setClassData(res.data.items);
                setClassData(res.data.items[0]);
                setActiveView("view-data")
            }
        })
    }

    return (
        <div className="join-class-container">
            <div style={{fontSize: "18px", fontWeight: "bold"}}>加入班级</div>
            <Space height="10px"/>
            <LineWidget width="100%" height="1px" color="rgb(211, 211, 211)"/>
            <div className="join-class-search-container">
                <Input
                    onChange={(e)=>{setClassId(e.target.value);}}
                    placeholder="请输入班级号"
                />
                <Space width="10px"/>
                <Button onClick={onSearch} type="primary"><TextWithSpace lspace={4} rspace={4}>搜索</TextWithSpace></Button>
            </div>
            <Space height="30px"/>
            <div>
                {renderView()}
            </div>
        </div>
    )
}

export default JoinClass;
