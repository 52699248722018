import React, { createContext, useState } from 'react';

const DataContext = createContext();

function DataProvider({ children }) {
    const [message, setMessage] = useState('');

    // 创建班级
    const [createClassDialog, setCreateClassDialog] = useState(false);

    // 创建讲座
    const [createPublicCourseDialog, setCreatePublicCourseDialog] = useState(false);

    // 创建课节
    const [createCourseDialog, setCreateCourseDialog] = useState(false);

    return (
        <DataContext.Provider value={{
            createClassDialog, setCreateClassDialog,
            createPublicCourseDialog, setCreatePublicCourseDialog,
            createCourseDialog, setCreateCourseDialog
        }}>
            {children}
        </DataContext.Provider>
    );
}

export { DataContext, DataProvider };
