import React from "react";
import { Routes, useNavigate, Route } from "react-router-dom";
import './AppRouter.css'
import LivePage_Student from "../LivePage/student/LivePage_Student";
import CameraSelection from "../Components/DeviceSelect/CameraSelection";
import LivePage_Teacher from "../LivePage/teacher/LivePage_Teacher";
import RtcPlayPage_Teacher from "../LivePage/teacher/RtcPlayPage_Teacher";
import LoginPage from "../Login/LoginPage";
import ConsolePage from "../Console/ConsolePage";

function AppRouter(){
    return(
        <div className="app-router-main-div" style={{height: '100%'}}>
            <Routes className='mainDiv'>
                <Route path="/DeviceSelect" element={<CameraSelection/>} />
                <Route path="/RtcPlay" element={<RtcPlayPage_Teacher/>} />
                <Route path="/" element={<LoginPage/>} />
            </Routes>
        </div>
    )
}

export default AppRouter;
