import React, {useMemo, useState} from "react";
import ReactDOM from 'react-dom/client';
import {ExclamationCircleOutlined} from '@ant-design/icons';

import "./MessageTip.css";

function MessageTip({msg}) {

    const [messages, setMessages] = useState([]);

    const addMessage = (content) => {
        setMessages(messages.concat(content));
    };

    return (
        <div style={{top: 10, left: 10 }} className="msg-body">
            <ExclamationCircleOutlined style={{ color: 'red', fontSize: '24px' }} />
            <span style={{color: "red", marginLeft: "8px"}}>{msg}</span>
        </div>
    );
}

class MessageItem
{
    constructor(props) {

    }

    error(container, content) {
        const ele = document.createElement('div');
        ele.className = "msg-container";
        container.appendChild(ele);

        const root = ReactDOM.createRoot(ele);
        root.render(<MessageTip msg={content}/>);
        setTimeout(()=>{
            root.unmount();
            container.removeChild(ele);
        }, 5000)
    }
}

const message = new MessageItem;

export default message;
