import React, {useContext, useEffect, useState} from 'react';
import './RtcPlayPage_Student.css'
import Util from "../Util";
import {DataContext_Student} from "./DataProvider_Student";

function RtcPlayItem({data}) {

    useEffect(()=>{

        let stuId = `${data.id}`;
        let elementId = 'id-' + stuId;
        let url = 'webrtc://play.xiaoyunduoyuan.com/live/s_' + stuId;

        console.log("play uuid, ", document.getElementById(elementId))
        window.loadVideo(url, elementId);

    }, [])


    return (
        <div className={`video-container-s`}>
            <video className="video_rtc" id={`id-${data.id}`} preload="auto" playsInline webkit-playsinline="true"/>
        </div>
    );
}

let messageCache = [];
messageCache.push({
    uuid: "s" + Util.guid().substr(0, 8),
})

function RtcPlayPage_Student({ message }) {
    let [updateMsg, setUpdateMsg] = useState(1);

    const {rtcList, setRtcList} = useContext(DataContext_Student);
    const {rtcListChanged, setRtcListChanged} = useContext(DataContext_Student);

    useEffect(()=>{

    }, [])

    return (
        <div className="top-rtc-s">
            {
                rtcListChanged && rtcList.map((msg, index) => (
                    <RtcPlayItem key={msg.id} data={msg}/>
                ))
            }
        </div>
    );
}

export default RtcPlayPage_Student;
