import React, {useEffect, useState} from 'react';
import { Popover, Input, Button, Row, Col } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import "./TimerPicker.css"

const TimePicker = ({onChange, defaultTime}) => {
    const [visible, setVisible] = useState(false);
    const [selectedHour, setSelectedHour] = useState(defaultTime[0]);
    const [selectedMinute, setSelectedMinute] = useState(defaultTime[1]);

    const handleHourClick = (hour) => {
        setSelectedHour(hour);
        _onChange(hour, selectedMinute);
    };

    const handleMinuteClick = (minute) => {
        setSelectedMinute(minute);
        _onChange(selectedHour, minute);
    };

    const _onChange = (h, m)=>{
        onChange(h, m);
    }

    const content = (
        <div className="custom-popover-content">
            <Row gutter={8}>
                <Col span={12}>
                    <div>
                        <div style={{ maxHeight: 200, overflowY: 'auto' }}>
                            {Array.from({ length: 24 }, (_, hour) => (
                                <div
                                    className="picker-item"
                                    key={hour}
                                    style={{
                                        background: selectedHour === hour ? 'rgba(22,119,255)' : 'transparent',
                                        color: selectedHour === hour ? 'white' : 'black',
                                        textAlign: "center",
                                        width: "100%",
                                        marginBottom: 4
                                    }}
                                    onClick={() => handleHourClick(hour)}
                                >
                                    {hour}小时
                                </div>
                            ))}
                        </div>
                    </div>
                </Col>
                <Col span={12}>
                    <div>
                        <div style={{ maxHeight: 200, overflowY: 'auto' }}>
                            {Array.from({ length: 60 }, (_, minute) => (
                                <div
                                key={minute}
                                style={{
                                    background: selectedMinute === minute ? 'rgba(22,119,255)' : 'transparent',
                                    color: selectedMinute === minute ? 'white' : 'black',
                                    textAlign: "center",
                                    width: "100%",
                                    marginBottom: 4
                                }}
                                onClick={() => handleMinuteClick(minute)}
                                >
                                {minute}分钟
                                </div>
                            ))}
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );

    return (
        <div className="tp_pop">
            <Popover
                content={content}
                trigger="click"
                visible={visible}
                placement="bottom"
                overlayClassName="custom-popover"
                onVisibleChange={(flag) => setVisible(flag)}
            >
                <Input
                    readOnly
                    value={
                        selectedHour !== null && selectedMinute !== null
                            ? `${selectedHour}小时${selectedMinute}分钟`
                            : ''
                    }
                    placeholder="选择时间"
                    suffix={<DownOutlined />}
                />
            </Popover>
        </div>
    );
};

export default TimePicker;
