import React, { useEffect, useState, createRef } from 'react';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Button, Input} from 'antd';
import './LoginPage.css'
import Checkbox from "antd/es/checkbox";
import Select from "antd/es/select";
import Modal from "antd/es/modal";

const LoginPage = (props) => {

    useEffect(()=>{

        return ()=>{

        }
    }, []);

    function onLogin() {
        window.CallBridge.invokeMethod("login_success");
    }

    return (

        <div
            //title="Basic Modal"
            //visible={true}
            // onOk={handleOk}
            // onCancel={handleCancel}
        >
            <div>
                <img src=""/>
            </div>

            <div>
                <Select
                    defaultValue="lucy"
                    style={{
                        width: 60,
                    }}
                    //onChange={handleChange}
                    options={[
                        {
                            value: '+86',
                            label: '+86',
                        },
                    ]}
                />
                <Input></Input>
            </div>

            <p>Some contents...</p>
        </div>

        // <div className="login_container">
        //     {/*<div className="login_left">*/}
        //     {/*    <img className="login_left_pic" src="https://mdbootstrap.com/img/new/ecommerce/vertical/004.jpg"/>*/}
        //     {/*</div>*/}
        //     {/*<div className="login_right">*/}
        //     {/*    <div className="space_item"></div>*/}
        //     {/*    <Input className="right_item" placeholder="input username" />*/}
        //     {/*    <Input.Password className="right_item" placeholder="input password" />*/}
        //     {/*    <div direction="horizontal" className="right_item">*/}
        //     {/*        <Checkbox style={{color: "#3b71ca"}}>记住密码</Checkbox>*/}
        //     {/*        <div style={{color: "#3b71ca", fontSize: "12px"}}>忘记密码？</div>*/}
        //     {/*    </div>*/}
        //     {/*    <div style={{height: "22px"}}></div>*/}
        //     {/*    <Button type="primary" style={{backgroundColor: "#3b71ca"}} onClick={onLogin}>登  录</Button>*/}
        //     {/*    <div className="space_item"></div>*/}
        //     {/*</div>*/}
        //
        //
        //     <Modal
        //         title="Basic Modal"
        //         visible={true}
        //        // onOk={handleOk}
        //        // onCancel={handleCancel}
        //     >
        //         <div>
        //             <img src=""/>
        //         </div>
        //
        //         <div>
        //             <Select
        //                 defaultValue="lucy"
        //                 style={{
        //                     width: 60,
        //                 }}
        //                 //onChange={handleChange}
        //                 options={[
        //                     {
        //                         value: '+86',
        //                         label: '+86',
        //                     },
        //                 ]}
        //             />
        //             <Input></Input>
        //         </div>
        //
        //         <p>Some contents...</p>
        //     </Modal>
        //
        // </div>
    )
}

export default LoginPage;
