import React, {useContext, useEffect, useState} from 'react';
import './HomePage.css'
import {InfoCircleOutlined, PlusSquareOutlined, SearchOutlined, UserOutlined} from '@ant-design/icons';
import { Modal, Button, Flex, Tooltip, Input, Dropdown} from 'antd';
import ClassListItem from "./ClassListItem";
import { Avatar, List, Skeleton } from 'antd';
import request from "../../Components/net/request";

import {DataContext} from "../DataProvider";
import CreateClass from "./CreateClass";
import CreatePublicCourseDialog from "./CreatePublicCourseDialog";
import CreateCourseDialog from "./CreateCourseDialog";
import CourseListItem from "./CourseListItem";
import ImageButton from "../../Components/Common/ImageButton";
import JoinClass from "./JoinClass";

const HomePage = (props) => {

    const [classTitle, setClassTitle] = useState("");

    // 创建班级
    const {createClassDialog, setCreateClassDialog} = useContext(DataContext);
    // 创建讲座
    const {createPublicCourseDialog, setCreatePublicCourseDialog} = useContext(DataContext);
    // 创建课节
    const {createCourseDialog, setCreateCourseDialog} = useContext(DataContext);

    // 视图切换
    const [activeView, setActiveView] = useState('view-main');

    // 左侧的班级数据
    const [classData, setClassData] = useState([]);

    // 右侧的课节数据
    const [selectedCourseData, setSelectedCourseData] = useState([]);

    // 当前选中的班级信息
    const [selectedClassData, setSelectedClassData] = useState({})

    useEffect(()=>{

        // 开始获取班级列表数据
        request.get("/api/v1/course/list?page=1&page_size=500&order=join_time_desc").then(response => {
            const res = response.data;
            if (res.code == 200 && res.data) {
                setClassData(res.data.items);
            }
        })



    }, []);

    function classClicked(data) {
        setClassTitle(data.name);
        setActiveView('view-room');
        setSelectedClassData(data);

        // 获取课节信息
        request.get("/api/v1/course/lesson/list?course_uuid=" + data.uuid).then(response => {
            const res = response.data;
            console.log(res)
            if (res.code == 200 && res.data) {
                setSelectedCourseData(res.data);
            }
        })
    }

    // 创建班级
    function onCreateClass() {
        setCreateClassDialog(true)
    }

    // 创建班级，点击确定
    function onCreateClass_Ok() {
        request.post("/api/v1/course/regular/create", {
            "name":"第一个班级",
            "cover_image": "src"
        }).then(response => {
            console.log('Response data:', response.data);
            setCreateClassDialog(false);
        })
    }

    function onCreatePublicClicked() {
        setCreatePublicCourseDialog(true);
    }

    function onCreateCourseClicked() {
        setCreateCourseDialog(true);
    }

    const renderView = () => {
        switch (activeView) {
            case 'view-main':
                return (
                    <div className="home_icon_container">
                        <div>
                            <ImageButton src="./imgs/add_class.png" style={{margin: "30px"}}>加入班级</ImageButton>
                            <ImageButton src="./imgs/add_class.png" style={{margin: "30px"}} onClick={onCreateClass}>创建班级</ImageButton>
                        </div>
                        <div>
                            <ImageButton src="./imgs/add_class.png" style={{margin: "30px"}}>加入公开课</ImageButton>
                            <ImageButton src="./imgs/add_class.png" style={{margin: "30px"}}>创建公开课</ImageButton>
                        </div>
                    </div>
                )
            case 'view-room':
                return (
                    <div className="home_page_right">
                        <div className="home_page_title">{classTitle}</div>
                        <div className="home_page_kejie">课节</div>
                        <div className="home_page_kejie_list">
                            {
                                selectedCourseData.map((val, idx)=>{
                                    return <CourseListItem key={idx} data={val} order={idx+1} />
                                })
                            }
                        </div>
                        <div className="home_page_bottom">
                            <Button onClick={onCreateCourseClicked}>创建课节</Button>
                        </div>
                    </div>
                );
            case "view-join-class":
                return (
                   <JoinClass/>
                );
            default:
                return 'view-main';
        }
    };

    const items = [
        {
            label: <span
                onClick={()=>{
                    setActiveView('view-join-class');
                }}
            >加入班级</span>,
            key: '0',
        },
        {
            type: 'divider',
        },
        {
            label: <span>加入公开课</span>,
            key: '1',
        }
    ];

    return (
        <div className="home_page_container">
            <div className="home_page_left">
                <div className="search-container">
                    <Input
                        placeholder="搜索"
                        prefix={
                            <SearchOutlined
                                style={{
                                    color: 'rgba(0,0,0,.25)',
                                }}
                            />
                        }
                    />

                    <Dropdown
                        menu={{
                            items,
                        }}
                        trigger={['click']}
                    >

                        <PlusSquareOutlined className="add-button"
                                            style={{
                                                color: '#E9E9E9',
                                            }}

                                            onClick={(e) => e.preventDefault()}
                        />

                    </Dropdown>

                </div>
                <div className="create_bar_container">
                    <Button icon={<img src="./imgs/create_public.svg" style={{ width: '1em', height: '1em' }} />}
                            onClick={onCreatePublicClicked} >创建讲座</Button>
                    <Button icon={<img src="./imgs/create_class.svg" style={{ width: '1em', height: '1em' }} />}
                            onClick={onCreateClass}>创建班级</Button>
                </div>

                <div style={{height: "1px", width: "100%", background: "lightgray"}}></div>

                <div className="home_page_class_list">
                    {
                        classData.map((val, idx)=>{
                            return <ClassListItem key={idx} data={val} onClick={classClicked}/>
                        })
                    }
                </div>

            </div>
            {renderView()}
            {createClassDialog && <CreateClass onOk={onCreateClass_Ok}/>}
            {createPublicCourseDialog && <CreatePublicCourseDialog/>}
            {createCourseDialog && <CreateCourseDialog classData={selectedClassData}/>}
        </div>
    )
}

export default HomePage;
