import React, { useEffect } from 'react';
import './ConsolePage.css'
import BarItem from "./BarItem";
import {Route, Routes, useNavigate } from "react-router-dom";
import HomePage from "./HomePage/HomePage";
import HomePage_Live from "./HomePage_Live/HomePage_Live";
import CloudDiskPage from "./CloudDisk/CloudDiskPage";
import {DataContext, DataProvider} from "./DataProvider";

const ConsolePage = (props) => {

    const navigate = useNavigate();

    useEffect(()=>{
        return ()=>{

        }
    }, []);

    function onTabChanged(id) {
        console.log(id)
        if (id == "home") {
            navigate("/");
        } else if (id == "live") {
            navigate("/live");
        } else if (id == "cloud_disk") {
            navigate("/cloud_disk");
        }
    }

    return (
        <DataProvider>
            <div className="console_page_container">
                <div className="console_page_left">
                    <div className="head-pic-container">
                        <img src="imgs/head.png" className="head_pic" style={{width: "32px", height: "32px"}}/>
                    </div>
                    <BarItem itemText="班级" itemImgSrc="imgs/home_normal.svg" onClick={onTabChanged} id="home" />
                    <BarItem itemText="空间" itemImgSrc="imgs/clouddisk_normal.svg" onClick={onTabChanged} id="cloud_disk" default/>
                    {/*<BarItem itemText="直播" itemImgSrc="imgs/live_normal.svg" onClick={onTabChanged} id="live" />*/}
                    <BarItem itemText="黑板" itemImgSrc="imgs/dvr_normal.svg" onClick={onTabChanged} id="dvr" />
                </div>
                <Routes className='mainDiv'>
                    {<Route path="/" element={<HomePage/>} />}
                    <Route path="/cloud_disk" element={<CloudDiskPage/>} />
                    {/*<Route path="/dvr" element={<LivePage_Teacher/>} />*/}
                    {/*<Route path="/clouddisk" element={<RtcPlayPage_Teacher/>} />*/}
                </Routes>
            </div>
        </DataProvider>
    )
}

export default ConsolePage;
