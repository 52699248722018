import React, {createRef, useContext, useEffect, useRef, useState} from 'react';
import {Select, Modal, Input, DatePicker, Button, ConfigProvider} from "antd";
import {DataContext} from "../DataProvider";
import moment from 'moment';
import TimePicker from "../../Components/Common/TimerPicker";
import 'rc-time-picker/assets/index.css';
import "./CreatePublicCourseDialog.css"
import "../../Components/Common/Common.css"
import "./CreateCourseDialog.css"
import request from "../../Components/net/request";
import message from "../../Components/Common/MessageTip";

const { RangePicker } = DatePicker;

const CreateCourseDialog = ({classData}) => {

    const {createCourseDialog, setCreateCourseDialog} = useContext(DataContext);
    const [isDisabled, setIsDisabled] = useState(false);

    // 课程名字
    const [courseNameText, setCourseNameText] = useState("");
    // 开始时间
    const [startTime, setStartTime] = useState("");
    // 课程时长
    const [courseTime, setCourseTime] = useState([0, 45]);
    // 主讲老师ID
    const [mainTeacherId, setMainTeacherId] = useState("0");
    // 辅导老师ID
    const [tutorTeacherId, setTutorTeacherID] = useState("0");

    const handleOk = () => {

        if (courseNameText.length == 0) {
            message.error(
                document.getElementsByClassName("class_create_course")[0].getElementsByClassName("ant-modal-content")[0],
                '请输入课节名称！'
            );

            return;
        }

        if (startTime.length == 0) {
            message.error(
                document.getElementsByClassName("class_create_course")[0].getElementsByClassName("ant-modal-content")[0],
                '请选择开始时间！'
            );

            return;
        }

        let date = moment(startTime, "YYYY-MM-DD HH:mm:ss").toDate();
        date.setHours(date.getHours() + courseTime[0]);
        date.setMinutes(date.getMinutes() + courseTime[1]);

        const startTime_iso = moment(startTime, "YYYY-MM-DD HH:mm:ss").toDate().toISOString();
        const expireTime_iso = date.toISOString();

        request.post("/api/v1/course/lesson/create", {
            "name": courseNameText,
            "course_uuid": classData.uuid,
            "team_uuid": classData.team_uuid,
            "teacher_id": window.user_info.user.id,
            "assistant_ids": [window.user_info.user.id],
            "begin_time": startTime_iso,
            "end_time": expireTime_iso,
            "onstage_users_limit": 128,
            "is_auto_onstage": false
        }).then(response => {
            console.log('Response data:', response.data);
            setCreateCourseDialog(false);
        }).catch((err)=>{
            setIsDisabled(false);
        });
    };
    const handleCancel = () => {
        setCreateCourseDialog(false);
    };

    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };

    const dropdownStyle = {
        textAlign: "left"
    }

    return (
        <Modal
            wrapClassName="class_create_course"
            open={createCourseDialog}
            title="创建课节"
            onOk={handleOk}
            onCancel={handleCancel}
            transitionName=""  // 禁用 Modal 弹出动画
            maskTransitionName=""  // 禁用遮罩层动画
            mask={false}
            footer={
                [
                    <Button key="back" onClick={handleCancel}>
                        取消
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleOk} disabled={isDisabled}>
                        提交
                    </Button>,
                ]
            }
        >
            <div className="dialog_line"></div>
            <div className="class_normal">课节名称</div>
            <Input
                onChange={(e)=>{
                    setCourseNameText(e.target.value);
                }}
            ></Input>
            <div className="class_normal">开始时间</div>
            <DatePicker
                style={{
                    width: "100%",
                }}

                showTime={{
                    format: 'HH:mm',
                }}

                onChange={(value, dateString) => {
                    console.log('Selected Time: ', value);
                    console.log('Formatted Selected Time: ', dateString);
                    setStartTime(dateString);
                }}
                //onOk={onOk}
            />
            <div className="class_normal">课程时长</div>
            <TimePicker

                style={{
                    width: "200px",
                }}

                onChange={(h, m)=>{
                    setCourseTime([h, m]);
                }}

                defaultTime = {courseTime}

                placeholder="选择时间"
            />
            <div className="t-container">
                <div className="t-container-item">
                    <div className="class_normal">授课老师</div>
                    <Select
                        defaultValue=""
                        className="custom-select"
                        style={{
                            width: "100%",
                        }}
                        dropdownStyle={dropdownStyle}
                        onChange={handleChange}
                        options={[
                            {
                                value: window.user_info.user.id,
                                label: window.user_info.user.nickname,
                            }
                        ]}
                    />
                </div>
                <div className="h-space-item"></div>
                <div className="t-container-item">
                    <div className="class_normal">联席老师</div>
                    <Select
                        defaultValue=""
                        className="custom-select"
                        style={{
                            width: "100%",
                        }}
                        dropdownStyle={dropdownStyle}
                        onChange={handleChange}
                        options={[
                            {
                                value: window.user_info.user.id,
                                label: window.user_info.user.nickname,
                            },
                        ]}
                    />
                </div>
            </div>
        </Modal>
    );
}

export default CreateCourseDialog;
